
.subpage-style h2{ 
    @apply  text-[32px] mb-2;
}
.subpage-style h4{ 
    @apply text-xl lg:text-2xl mb-2 font-bold;
}
.subpage-style h4 strong{
    @apply font-bold
}
.subpage-style p{
    @apply text-[16px] text-black text-justify mb-4;
}
.subpage-style ul{
    @apply ml-10 mb-5
}
.subpage-style ul li {
    @apply text-[16px] text-black text-justify  list-disc
}
.radio-but{
    @apply   text-gray-500 bg-white border border-[#ced4da] rounded-lg cursor-pointer  peer-checked:text-white peer-checked:border-[#00a400] peer-checked:bg-[#00a400] hover:text-white hover:bg-[#00a400] hover:border-[#00a400];
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}